import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { Space, Typography, Card, Row, Col, Alert } from 'antd';
import { blogData } from '../../data/data';

const { Title, Paragraph, Text } = Typography;
const { Meta } = Card;

function Post() {
  const { href } = useParams();
  const navigate = useNavigate();
  // find blog data from array of fetched blogs
  const newPost = blogData.find((post) => post.href === href);
  // split article to paragraphs
  const paragraphs = newPost.article.split(/\n\n/);

  // Alert error on close redirect back to homepage
  const onClose = () => {
    navigate('/');
  };

  if (!newPost) {
    return (
      <Alert
        message="Error Text"
        description="Sorry :( This page didn't load correctly"
        type="error"
        closable
        onClose={onClose}
      />
    );
  }

  return (
    <Row justify="center" align="middle">
      <Col xs={24} sm={24} md={20} lg={18} xl={18}>
        <Space direction="vertical" size="middle">
          <Card style={{ width: '85%', padding: '1%', margin: '5%' }}>
            <Meta
              title={<Title level={2}>{newPost.title}</Title>}
              description={<Text type="secondary">{newPost.author}</Text>}
            />
            {/* <Paragraph style={{ margin: '2%', fontSize: '1.2rem' }}>
              {newPost.article}
            </Paragraph> */}

            {paragraphs.map((para) => (
              <Paragraph
                key={`${newPost.href}`}
                style={{
                  whiteSpace: 'pre-wrap',
                  margin: '3%',
                  fontSize: '1.2rem',
                  textAlign: 'left',
                }}>
                {para}
              </Paragraph>
            ))}
          </Card>
          {/* <Title level={2}>{newPost.title}</Title>
      <Title level={4}>{newPost.author}</Title> */}
        </Space>
      </Col>
    </Row>
  );
}

// Post.PropTypes = {
//   item: PropTypes.objectOf(
//     PropTypes.shape({
//       href: PropTypes.string.isRequired,
//       image: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       post: PropTypes.string.isRequired,
//       author: PropTypes.string.isRequired,
//     }),
//   ),
// };

export default Post;
