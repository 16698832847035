import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, List, Space, Divider } from 'antd';
import axios from 'axios';
import { useArticles } from './BlogContext';
// import Post from './Post';
// import { blogData } from '../../data/data';

import './blog.css';

const { Title, Paragraph } = Typography;

function Blog() {
  // Fetch the latest 5 blogs written
  const { articles, setArticles } = useArticles();

  useEffect(() => {
    if (!articles) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/blog`)
        .then(({ data }) => {
          setArticles(data);
        })
        .catch((err) => {
          console.error('There was an error retrieving blog posts: ', err);
        });
    }
  }, [articles, setArticles]);

  if (!articles) return <div>Loading...</div>;

  return (
    <>
      <section className="blog-landing">
        <Row align="middle" justify="center">
          <Col>
            <Title level={2} style={{ color: '#fff', fontFamily: 'Roboto' }}>
              MWAKINA&apos;S BLOG
            </Title>
          </Col>
        </Row>
      </section>
      <section className="blog-list">
        <Row align="middle" justify="center">
          <Col xs={24} sm={20} md={20} lg={20} xl={20} span={4}>
            <List
              itemLayout="vertical"
              size="large"
              // pagination={{
              //   onChange: (page) => {
              //     console.log(page);
              //   },
              //   pageSize: 3,
              // }}
              dataSource={articles}
              renderItem={(item) => (
                <Space
                  direction="vertical"
                  size="large"
                  style={{ display: 'flex' }}>
                  <Divider />
                  <List.Item
                    key={item.title}
                    // extra={<img width={300} alt="blog" src={item.image} />}
                  >
                    <List.Item.Meta
                      title={
                        <Link to={`/blog/${item.href}`}>
                          <Title>{item.title}</Title>
                        </Link>
                      }
                      description={item.desc}
                    />
                    <Paragraph style={{ textAlign: 'left', margin: 0 }}>
                      {item.article.slice(0, 400)}...
                    </Paragraph>
                  </List.Item>
                </Space>
              )}
            />
          </Col>
        </Row>
      </section>
    </>
  );
}

export default Blog;
