import React, { createContext, useContext, useState } from 'react';
// import PropTypes from 'prop-types';

// Create a Context
const DataContext = createContext();

// Provider component
// eslint-disable-next-line react/prop-types
export function DataProvider({ children }) {
  const [articles, setArticles] = useState(null);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DataContext.Provider value={{ articles, setArticles }}>
      {children}
    </DataContext.Provider>
  );
}

// DataProvider.propTypes = {
//   children: PropTypes.any,
// };

// Hook to use the data context
export const useArticles = () => useContext(DataContext);
