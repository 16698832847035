import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Typography, Row, Col, Button, Space, Divider } from 'antd';
import {
  LinkedinOutlined,
  FacebookOutlined,
  XOutlined,
  InstagramOutlined,
} from '@ant-design/icons';
import RightImageSection from '../../components/RightImageSection/RightImageSection';
import LeftImageSection from '../../components/LeftImageSection/LeftImageSection';
import Comments from '../../components/Comments/Comments';
import EmailForm from '../../components/Forms/EmailForm';
import './home.css';
import {
  BOOK_LINK,
  rIMGURL,
  rTitle,
  rText,
  lIMGURL,
  lText,
  lTitle,
  LINKEDIN_PAGE,
  FACEBOOK_PAGE,
  INSTAGRAM_PAGE,
  X_PAGE,
} from '../../data/data';

const { Title } = Typography;

function Home() {
  const [rSectionValues, setrSectionValues] = useState({
    imgUrl: rIMGURL,
    title: rTitle,
    text: rText,
  });
  const [lSectionValues, setlSectionValues] = useState({
    imgUrl: lIMGURL,
    title: lTitle,
    text: lText,
  });
  const [comments, setComments] = useState();
  const [buttonLabel] = useState('SUBMIT');

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/comments`)
      .then(({ data }) => {
        setComments(data);
      })
      .catch((err) => {
        console.error('There was an error retrieving comments: ', err);
      });
  }, []);

  return (
    <>
      <div className="overlay">
        <section className="landing">
          <Row justify="start" align="middle" style={{ minHeight: '100vh' }}>
            <Col xs={24} sm={24} md={18} lg={15} xl={12}>
              {/* <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex', color: '#d3c8ad' }}> */}
              <Title
                level={1}
                className="landing-title"
                style={{ color: '#d3c8ad' }}>
                BREAKING THE BARRIERS
              </Title>
              <p className="landing-text">
                The memoir that unveils a journey of resilience, faith, and
                self-belief. Join Evelyne Mshai Mwakina, a distinguished figure
                with a remarkable 34-year civil service career and a stellar
                educational background
              </p>
              <Button type="primary" shape="round" size="large">
                <Link to={BOOK_LINK} target="_blank">
                  GET THE BOOK
                </Link>
              </Button>
              {/* </Space> */}
            </Col>
          </Row>
        </section>
      </div>
      <RightImageSection
        sectionValues={rSectionValues}
        setValues={setrSectionValues}
      />

      <Comments comments={comments} setComments={setComments} />

      <LeftImageSection
        sectionValues={lSectionValues}
        setValues={setlSectionValues}
      />
      <section className="socials">
        <Row justify="center" align="middle">
          <Space direction="vertical" size="middle">
            <Title level={2}>Connect with Evelyne Mwakina</Title>
            <Divider />
            <Row justify="space-evenly">
              <Col span={4}>
                <a
                  href={LINKEDIN_PAGE}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="linkedin">
                  <LinkedinOutlined className="icon-design" />
                </a>
              </Col>
              <Col span={4}>
                <a
                  href={FACEBOOK_PAGE}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="facebook">
                  <FacebookOutlined className="icon-design" />
                </a>
              </Col>
              <Col span={4}>
                <a
                  href={X_PAGE}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="x">
                  <XOutlined className="icon-design" />
                </a>
              </Col>
              <Col span={4}>
                <a
                  href={INSTAGRAM_PAGE}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="instagram">
                  <InstagramOutlined className="icon-design" />
                </a>
              </Col>
            </Row>
            <Space.Compact>
              <Title level={5}>
                Have insights or inspirations? Connect with your email
              </Title>
            </Space.Compact>
            <Space.Compact>
              <EmailForm label={buttonLabel} />
            </Space.Compact>
          </Space>
        </Row>
      </section>
    </>
  );
}

export default Home;
