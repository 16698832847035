import React, { useState, useEffect } from 'react';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { BOOK_LINK } from '../../data/data';
import './navbar.css';

function Navbar() {
  // For toggling on responsiveness with smaller screens
  const [isOpen, setIsOpen] = useState(false);
  // For scrolling event
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    // Attach the event listener on mount
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const onLinkClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`nav ${scrolled ? ' scroll' : ''}`}>
      <div className="navbar-brand">
        <Link
          to="/"
          className={`site-title ${scrolled ? ' scroll-title' : ''}`}>
          Evelyne Mwakina
        </Link>
        <button type="button" className="navbar-toggle" onClick={toggleNavbar}>
          <span className="toggle-icon">&#9776;</span>
        </button>
      </div>
      <ul className={`navbar-links ${isOpen ? 'active' : ''}`}>
        <CustomLink to="/" onLinkClick={onLinkClick}>
          HOME
        </CustomLink>

        <CustomLink to="/about" onLinkClick={onLinkClick}>
          ABOUT
        </CustomLink>

        <CustomLink to="/books" onLinkClick={onLinkClick}>
          BOOKS
        </CustomLink>

        <CustomLink to="/contact" onLinkClick={onLinkClick}>
          CONTACT
        </CustomLink>
        {/* TODO: Build backend first inorder to include this link */}
        <CustomLink to="/blog" onLinkClick={onLinkClick}>
          BLOG
        </CustomLink>
        <Button type="primary" shape="round" size="large">
          <Link to={BOOK_LINK} target="_blank">
            GET THE BOOK
          </Link>
        </Button>
      </ul>
    </nav>
  );
}
// Render list elements dynamically
function CustomLink({ to, children, onLinkClick }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname });

  return (
    <li className={isActive ? 'active' : ''}>
      <Link
        to={to}
        onClick={() => {
          onLinkClick();
        }}>
        {children}
      </Link>
    </li>
  );
}

CustomLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.string,
  onLinkClick: PropTypes.func,
};

export default Navbar;
