import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { DataProvider } from './pages/Blog/BlogContext';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Blog from './pages/Blog/Blog';
import Post from './pages/Blog/Post';
import Books from './pages/Books/Books';
import Contact from './pages/Contact/Contact';
import Footer from './components/Footer/Footer';

import './App.css';

// const { Header } = Layout;

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();

    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <div className="App">
      <section>
        <Navbar />
        <ScrollToTop />
        <DataProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:href" element={<Post />} />
            <Route path="/books" element={<Books />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </DataProvider>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default App;
