// IMAGES
import rBgSection from '../assets/bg-about.jpg';
import lBgSection from '../assets/bg-lsection.jpg';
import blogger from '../assets/blog_sprite.jpg';

// BOOK LINK
export const BOOK_LINK =
  'https://nuriakenya.com/product/breaking-the-barriersempowered-girlchild-by-evelyne-mwakina/';

// SECTIONS DATA
export const rIMGURL = rBgSection;
export const rTitle = 'ABOUT THE BOOK';
export const rText = `Embark on a transformative journey through 'Breaking the Barriers: Empowered Girl Child.' Uncover moments where resilience, faith, and an abundance mindset collided to shape a narrative of triumph over adversity. \n Explore the depths of courage and perseverance as you navigate through the pages of empowerment, guided by Evelyne's remarkable life experiences.`;

export const lIMGURL = lBgSection;
export const lTitle = 'ABOUT THE AUTHOR';
export const lText = `Meet Evelyne Mshai Mwakina, the storyteller behind 'Breaking the Barriers: Empowered Girl Child.' Explore her personal narrative and the invaluable lessons learned, empowering girls to overcome challenges with unwavering determination, hard work, and profound trust in God. Discover the untold stories that inspire a new generation of resilient and empowered young women.`;

// SOCIAL MEDIA LINKS
export const LINKEDIN_PAGE =
  'https://www.linkedin.com/in/evelyne-mwakina-mprsk-64381034';
export const FACEBOOK_PAGE = 'https://www.facebook.com/evelyne.mwakina';
export const X_PAGE = 'https://x.com/evelynemwakina';
export const INSTAGRAM_PAGE = 'https://www.instagram.com/emwakina';

// Comments
export const sampleComments = [
  // {
  //   comment: `I have read your book and am amazed because of how powerful and deep it is. I want to read it again and again it is inspiring and challenging at the same time. I trust others will be blessed by this power filled book.`,
  //   author: 'Emma',
  //   title: 'Womens Ministry, CITAM Karen',
  // },
  // {
  //   comment: `Breaking the Barriers unfolds Evelyne Mwakina’s captivating
  //   journey. Early on, Evelyne formed a deep bond with our mother,
  //   shaped by a compassionate heart and a unique upbringing. Amidst
  //   the struggles of a large family, our lives were transformed by our
  //   mother’s spiritual journey in 1961, becoming a defining force
  //   for us all. Evelyne’s story is a demonstration of faith, overcoming
  //   struggles, and triumphantly succeeding in her journey of life. It
  //   proves that a faith-driven and family-nurtured Empowered Girl
  //   Child can shatter any barriers, offering motivation to all girls`,
  //   author: 'P.E. Mwaisaka',
  //   title: 'EBS, CBS, Elder Brother',
  // },
  // {
  //   comment: `Evelyne story is the epitome of an Empowered Girl Child,
  //   breaking free from societal constraints. She inspires us all to
  //   embrace our true selves. Her story reminds us that we should
  //   embrace our identities, shatter barriers and create a future where
  //   our dreams take flight.`,
  //   author: 'Hon. Hassan Osman',
  //   title: 'Director, Kenya Broadcasting Corporation',
  // },
  // {
  //   comment: `In summation, Evelyne Mwakina is a rare gem. She is a steadfast
  // pillar of strength to her friends and family. She embodies the
  // essence of breaking barriers and empowering the Girl Child.
  // May God’s abundant blessings be poured upon her`,
  //   author: 'Lydia W. Njoroge',
  //   title: 'Member, Flower Ministry, CITAM Karen',
  // },
];

export const blogData = [
  {
    href: 'my-journey',
    image: blogger,
    title: 'My Journey in Corporate',
    article: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed magna semper, fringilla mauris sagittis, rutrum est. Donec eu leo non dui tincidunt convallis. Sed iaculis eros odio, sit amet euismod quam euismod pulvinar. Suspendisse fringilla tincidunt nibh, viverra feugiat leo bibendum et. Suspendisse sed risus non magna varius egestas in ut ipsum. Donec scelerisque, dui at dictum sollicitudin, ante ante sodales nisi, sed fermentum est velit a nibh. Nunc imperdiet hendrerit lectus, in accumsan nisl blandit vel. In eleifend elementum risus, eget iaculis risus sodales ut. Nam egestas enim sed ipsum tempor, ac egestas risus egestas. Sed non luctus tellus. Duis aliquet tristique nulla eu interdum.

Proin et vestibulum lorem, id malesuada ipsum. Nunc lobortis arcu eget sodales aliquam. Suspendisse sit amet sollicitudin nisl. Aliquam quis semper tellus, non congue mi. Cras venenatis ex vel dolor semper mollis. Suspendisse eget orci molestie, tristique lacus ut, pellentesque diam. Proin sed augue elit. Phasellus laoreet, odio ut gravida luctus, nunc magna iaculis tellus, in accumsan odio diam id ligula. Donec consequat, quam quis tincidunt tincidunt, quam nisl efficitur nisi, nec facilisis felis felis ac nisl. Pellentesque ornare justo leo, id hendrerit diam pretium eu. Maecenas finibus mi congue neque ornare, molestie accumsan magna malesuada. Nulla a felis eu tortor viverra placerat vitae a tortor. Donec porttitor tortor sem, ac pellentesque odio sollicitudin sit amet. Aenean et commodo nunc. Praesent tempor lorem sem, sollicitudin rutrum est dignissim eu.

Vestibulum commodo metus vel lectus volutpat vehicula. Ut at urna ipsum. Aliquam eget felis sem. Donec bibendum, velit nec ornare vestibulum, lacus nibh pulvinar tortor, ultricies faucibus arcu dolor at odio. Integer cursus, metus ut volutpat hendrerit, turpis magna varius nulla, at efficitur magna sapien sed orci. Nulla sollicitudin, erat at vulputate hendrerit, nisl ligula egestas nunc, quis semper elit nisl lobortis ipsum. Donec congue eleifend gravida. Pellentesque commodo sed tellus id maximus. Suspendisse arcu eros, tincidunt rhoncus condimentum in, dapibus vitae nisl. Suspendisse ultrices sem a consequat ullamcorper. Duis interdum lorem non iaculis blandit. Praesent hendrerit sem id sem hendrerit ultricies. Etiam sodales quis neque et ultricies. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
    author: 'Evelyne Mwakina',
    desc: '',
  },
  {
    href: 'culture-women',
    image: blogger,
    title: 'Culture, Women and Career',
    article: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed magna semper, fringilla mauris sagittis, rutrum est. Donec eu leo non dui tincidunt convallis. Sed iaculis eros odio, sit amet euismod quam euismod pulvinar. Suspendisse fringilla tincidunt nibh, viverra feugiat leo bibendum et. Suspendisse sed risus non magna varius egestas in ut ipsum. Donec scelerisque, dui at dictum sollicitudin, ante ante sodales nisi, sed fermentum est velit a nibh. Nunc imperdiet hendrerit lectus, in accumsan nisl blandit vel. In eleifend elementum risus, eget iaculis risus sodales ut. Nam egestas enim sed ipsum tempor, ac egestas risus egestas. Sed non luctus tellus. Duis aliquet tristique nulla eu interdum.


Proin et vestibulum lorem, id malesuada ipsum. Nunc lobortis arcu eget sodales aliquam. Suspendisse sit amet sollicitudin nisl. Aliquam quis semper tellus, non congue mi. Cras venenatis ex vel dolor semper mollis. Suspendisse eget orci molestie, tristique lacus ut, pellentesque diam. Proin sed augue elit. Phasellus laoreet, odio ut gravida luctus, nunc magna iaculis tellus, in accumsan odio diam id ligula. Donec consequat, quam quis tincidunt tincidunt, quam nisl efficitur nisi, nec facilisis felis felis ac nisl. Pellentesque ornare justo leo, id hendrerit diam pretium eu. Maecenas finibus mi congue neque ornare, molestie accumsan magna malesuada. Nulla a felis eu tortor viverra placerat vitae a tortor. Donec porttitor tortor sem, ac pellentesque odio sollicitudin sit amet. Aenean et commodo nunc. Praesent tempor lorem sem, sollicitudin rutrum est dignissim eu.


Vestibulum commodo metus vel lectus volutpat vehicula. Ut at urna ipsum. Aliquam eget felis sem. Donec bibendum, velit nec ornare vestibulum, lacus nibh pulvinar tortor, ultricies faucibus arcu dolor at odio. Integer cursus, metus ut volutpat hendrerit, turpis magna varius nulla, at efficitur magna sapien sed orci. Nulla sollicitudin, erat at vulputate hendrerit, nisl ligula egestas nunc, quis semper elit nisl lobortis ipsum. Donec congue eleifend gravida. Pellentesque commodo sed tellus id maximus. Suspendisse arcu eros, tincidunt rhoncus condimentum in, dapibus vitae nisl. Suspendisse ultrices sem a consequat ullamcorper. Duis interdum lorem non iaculis blandit. Praesent hendrerit sem id sem hendrerit ultricies. Etiam sodales quis neque et ultricies. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
    author: 'Evelyne Mwakina',
    desc: '',
  },
  {
    href: 'balance-between',
    image: blogger,
    title: 'Balance Between Family and Career',
    article: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed magna semper, fringilla mauris sagittis, rutrum est. Donec eu leo non dui tincidunt convallis. Sed iaculis eros odio, sit amet euismod quam euismod pulvinar. Suspendisse fringilla tincidunt nibh, viverra feugiat leo bibendum et. Suspendisse sed risus non magna varius egestas in ut ipsum. Donec scelerisque, dui at dictum sollicitudin, ante ante sodales nisi, sed fermentum est velit a nibh. Nunc imperdiet hendrerit lectus, in accumsan nisl blandit vel. In eleifend elementum risus, eget iaculis risus sodales ut. Nam egestas enim sed ipsum tempor, ac egestas risus egestas. Sed non luctus tellus. Duis aliquet tristique nulla eu interdum.

Proin et vestibulum lorem, id malesuada ipsum. Nunc lobortis arcu eget sodales aliquam. Suspendisse sit amet sollicitudin nisl. Aliquam quis semper tellus, non congue mi. Cras venenatis ex vel dolor semper mollis. Suspendisse eget orci molestie, tristique lacus ut, pellentesque diam. Proin sed augue elit. Phasellus laoreet, odio ut gravida luctus, nunc magna iaculis tellus, in accumsan odio diam id ligula. Donec consequat, quam quis tincidunt tincidunt, quam nisl efficitur nisi, nec facilisis felis felis ac nisl. Pellentesque ornare justo leo, id hendrerit diam pretium eu. Maecenas finibus mi congue neque ornare, molestie accumsan magna malesuada. Nulla a felis eu tortor viverra placerat vitae a tortor. Donec porttitor tortor sem, ac pellentesque odio sollicitudin sit amet. Aenean et commodo nunc. Praesent tempor lorem sem, sollicitudin rutrum est dignissim eu.

Vestibulum commodo metus vel lectus volutpat vehicula. Ut at urna ipsum. Aliquam eget felis sem. Donec bibendum, velit nec ornare vestibulum, lacus nibh pulvinar tortor, ultricies faucibus arcu dolor at odio. Integer cursus, metus ut volutpat hendrerit, turpis magna varius nulla, at efficitur magna sapien sed orci. Nulla sollicitudin, erat at vulputate hendrerit, nisl ligula egestas nunc, quis semper elit nisl lobortis ipsum. Donec congue eleifend gravida. Pellentesque commodo sed tellus id maximus. Suspendisse arcu eros, tincidunt rhoncus condimentum in, dapibus vitae nisl. Suspendisse ultrices sem a consequat ullamcorper. Duis interdum lorem non iaculis blandit. Praesent hendrerit sem id sem hendrerit ultricies. Etiam sodales quis neque et ultricies. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
    author: 'Evelyne Mwakina',
    desc: '',
  },
  {
    href: 'intuition-requires',
    image: blogger,
    title: 'Intuition Requires Action',
    article: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed magna semper, fringilla mauris sagittis, rutrum est. Donec eu leo non dui tincidunt convallis. Sed iaculis eros odio, sit amet euismod quam euismod pulvinar. Suspendisse fringilla tincidunt nibh, viverra feugiat leo bibendum et. Suspendisse sed risus non magna varius egestas in ut ipsum. Donec scelerisque, dui at dictum sollicitudin, ante ante sodales nisi, sed fermentum est velit a nibh. Nunc imperdiet hendrerit lectus, in accumsan nisl blandit vel. In eleifend elementum risus, eget iaculis risus sodales ut. Nam egestas enim sed ipsum tempor, ac egestas risus egestas. Sed non luctus tellus. Duis aliquet tristique nulla eu interdum.

Proin et vestibulum lorem, id malesuada ipsum. Nunc lobortis arcu eget sodales aliquam. Suspendisse sit amet sollicitudin nisl. Aliquam quis semper tellus, non congue mi. Cras venenatis ex vel dolor semper mollis. Suspendisse eget orci molestie, tristique lacus ut, pellentesque diam. Proin sed augue elit. Phasellus laoreet, odio ut gravida luctus, nunc magna iaculis tellus, in accumsan odio diam id ligula. Donec consequat, quam quis tincidunt tincidunt, quam nisl efficitur nisi, nec facilisis felis felis ac nisl. Pellentesque ornare justo leo, id hendrerit diam pretium eu. Maecenas finibus mi congue neque ornare, molestie accumsan magna malesuada. Nulla a felis eu tortor viverra placerat vitae a tortor. Donec porttitor tortor sem, ac pellentesque odio sollicitudin sit amet. Aenean et commodo nunc. Praesent tempor lorem sem, sollicitudin rutrum est dignissim eu.

Vestibulum commodo metus vel lectus volutpat vehicula. Ut at urna ipsum. Aliquam eget felis sem. Donec bibendum, velit nec ornare vestibulum, lacus nibh pulvinar tortor, ultricies faucibus arcu dolor at odio. Integer cursus, metus ut volutpat hendrerit, turpis magna varius nulla, at efficitur magna sapien sed orci. Nulla sollicitudin, erat at vulputate hendrerit, nisl ligula egestas nunc, quis semper elit nisl lobortis ipsum. Donec congue eleifend gravida. Pellentesque commodo sed tellus id maximus. Suspendisse arcu eros, tincidunt rhoncus condimentum in, dapibus vitae nisl. Suspendisse ultrices sem a consequat ullamcorper. Duis interdum lorem non iaculis blandit. Praesent hendrerit sem id sem hendrerit ultricies. Etiam sodales quis neque et ultricies. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
    author: 'Evelyne Mwakina',
    desc: '',
  },
];
