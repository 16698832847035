import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  Row,
  Col,
  Button,
  Space,
  Input,
  Form,
  Divider,
  Alert,
} from 'antd';
import {
  LinkedinOutlined,
  FacebookOutlined,
  XOutlined,
  InstagramOutlined,
} from '@ant-design/icons';
import {
  LINKEDIN_PAGE,
  FACEBOOK_PAGE,
  INSTAGRAM_PAGE,
  X_PAGE,
} from '../../data/data';

import './contact.css';

const { Title } = Typography;
const { TextArea } = Input;

function Contact() {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState({ visible: false, message: '', type: '' });

  const handleSubmit = (values) => {
    // const formValues = form.getFieldValue();
    console.log('Form values of email', values);

    useEffect(() => {
      axios
        .post(`http://localhost:3030/sendemail`, values)
        .then((data) => {
          const statusCode = data.status;
          if (statusCode === 200 || statusCode === 201) {
            setAlert({
              visible: true,
              message: 'Successfully sent email',
              type: 'success',
            });
          }
        })
        .catch(() => {
          setAlert({
            visible: true,
            message: 'There was a problem sending your email',
            type: 'error',
          });
        })
        .finally(() => {
          form.resetFields();
        });

      // set a timeout for all alert
      const timer = setTimeout(() => {
        setAlert({ visible: false });
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }, []);
  };

  const onCloseAlert = () => {
    setAlert({ ...alert, visible: false });
  };

  return (
    <>
      <section className="contact-landing">
        <Row justify="start" align="middle" style={{ minHeight: '80vh' }}>
          <Col>
            <Title level={2} style={{ color: '#fff', fontFamily: 'Roboto' }}>
              LET&apos;S CHAT AND CONNECT
            </Title>
            <Divider />
          </Col>
        </Row>
      </section>
      <section>
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
            <Form
              form={form}
              name="sendEmail"
              layout="vertical"
              autoComplete="off"
              size="large"
              onFinish={handleSubmit}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input maxLength={25} />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                type="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                  },
                ]}>
                <Input maxLength={50} />
              </Form.Item>
              <Form.Item
                name="subject"
                label="Subject"
                rules={[
                  {
                    required: false,
                  },
                ]}>
                <Input placeholder="Your Email Subject" maxLength={25} />
              </Form.Item>
              <Form.Item
                name="content"
                label="Your Message"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <TextArea
                  rows={4}
                  placeholder="Max Length is 120 words"
                  maxLength={1000}
                />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button htmlType="reset">RESET</Button>
                  <Button type="primary" htmlType="submit">
                    SEND EMAIL
                  </Button>
                </Space>
              </Form.Item>
            </Form>
            {alert.visible && (
              <Alert
                message={alert.message}
                type={alert.type}
                closable
                showIcon
                onClose={onCloseAlert}
              />
            )}
          </Col>
        </Row>
      </section>
      <section className="socials-contact">
        <Row justify="center" align="middle">
          <Space direction="vertical" size="middle">
            <Title level={2}>CONNECT WITH EVELYNE</Title>
            <Row justify="space-evenly">
              <Col span={4}>
                <a
                  href={LINKEDIN_PAGE}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="linkedin">
                  <LinkedinOutlined className="icon-design" />
                </a>
              </Col>
              <Col span={4}>
                <a
                  href={FACEBOOK_PAGE}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="facebook">
                  <FacebookOutlined className="icon-design" />
                </a>
              </Col>
              <Col span={4}>
                <a
                  href={X_PAGE}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="x">
                  <XOutlined className="icon-design" />
                </a>
              </Col>
              <Col span={4}>
                <a
                  href={INSTAGRAM_PAGE}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="instagram">
                  <InstagramOutlined className="icon-design" />
                </a>
              </Col>
            </Row>
          </Space>
        </Row>
      </section>
    </>
  );
}

export default Contact;
